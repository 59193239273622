import { defineStore } from 'pinia'
import { requestService } from '@/services/service.request';
import * as companyService from "@/services/service.company"
import Cookies from 'js-cookie';
import Constant from "@/helpers/Constant";
import Utils from '@/helpers/Utils';
import _ from 'lodash';


const state = () => ({
  currentCompany: false,
  companies:[],
  loading:false
});

// Getters
/*
const getSelectedCompany = state => index => {
  const company = state.companyList[index];
  const { additionalForm1, additionalForm2, companySetupDetail, ...rest } = company;
  const isPublished = company.publishStatus === 'published';

  const data = {
    companyClientType: isPublished ? company.companyClientType : company.companyClientType,
    registeredNumber: isPublished ? company.registeredNumber : company.registeredNumber,
    companyName: isPublished ? company.companyName : companySetupDetail.companyName,
    incorporationDate: company.incorporationDate,
    companyType: company.companyType,
    companyStatus: company.companyStatus,
    countryOfIncorporation: company.countryOfIncorporation,
    countryOfOperation: company.countryOfOperation,
    firstFinancialYearEndDate: company.firstFinancialYearEndDate,
    nextFinancialYearEndDate: company.nextFinancialYearEndDate,
    shareCapital: isPublished ? company.shareCapital : '',
    registeredAddress: isPublished ? company.registeredAddress : additionalForm1?.registeredAddress,
    officers: isPublished ? company.officers : additionalForm2?.directors,
    shareholders: isPublished ? company.shareholders : additionalForm2?.shareholders,
    publishStatus: company.publishStatus,
    documents: company.documents,
  };

  return data;
};
*/
export const useStoreCompany = defineStore('company', {
  state,
  getters: { 
    getSelectedCompany: (state) => {
      return state.currentCompany;
    },
    preferedSelectCompanyId: (state) => {
			return Cookies.get(Constant.COOKIE_PREFERED_SELECTED_COMPANY_ID);
		},
  
  },
  actions: {
    reset() {
      Object.assign(this, _.cloneDeep(obj));
    },
    setCurrentCompany(data){
      this.currentCompany = _.cloneDeep(data)
    },
    getAllCompany(){
      return requestService.getAllCompany()
        .then(res => {
          const d = res.data;
          this.companies = d.map(o=> { return this._tidyCompanyData(o)});
          return res;
        })
        .catch((err) => { console.error(' getAllCompany error: ', err); throw err; });
    },
    getDocuments(companyId) { 
      return companyService.getDocuments(companyId).then((res) => {
        return res.data.sort((a, b) => new Date(b?.updatedAt || b.createdAt ) - new Date(a?.updatedAt || a.createdAt));
      }).catch((err) => console.error('getDocuments err', err));
    },
    getSingleCompany(companyID){
      return companyService.getCompany(companyID)
    },
    getEvents(companyId) {
      return requestService.getEvents(companyId).then((res) => {
        // console.log('getEvents', res);
        return res.data
      }).catch((err) => {console.error('getEvents err', err)})
    },
    getRequests(companyId) {
      return requestService.getRequests(companyId).then((res) => {
        // console.log('getRequests', res);
        return res.data
      }).catch((err) => {console.error('getRequests err', err)})
    },
    getTimeline(companyId){
      return requestService.getDashboardTimeline(companyId)
          .then(res => {
              // console.log(` get dashboard timeline ${companyID} res:` , res.data);
              const sortedEvents = res.data.filter(v=>_.get(v, "eventHeldDate", null))
              .sort((a, b) => {
                  const aEventHeldDate = _.get(a, "eventHeldDate", null);
                  const bEventHeldDate = _.get(b, "eventHeldDate", null);

                  // Compare the eventDueDate values
                  if (aEventHeldDate !== null && bEventHeldDate !== null) {
                      // Both have defined eventDueDate, sort by eventDueDate
                      return new Date(b.eventHeldDate) - new Date(a.eventHeldDate);
                  } else if (aEventHeldDate === null && bEventHeldDate === null) {
                      // Both have undefined eventDueDate, keep the order as is
                      return 0;
                  } else if (aEventHeldDate !== null) {
                      // Only 'a' has a defined eventDueDate, it comes first
                      return -1;
                  } else {
                      // Only 'b' has a defined eventDueDate, it comes first
                      return 1;
                  }
              });
              //console.log("sortedEvents", sortedEvents)
              this.events = sortedEvents
              return sortedEvents;
          })
    },
    storeCompany(data){
      // console.log(data)
      //new accounts dont have companies
      if(!data.length) return

      //get local storage first for active company ID
      let _currentCompany = localStorage.getItem("activeCompany")
      //if local storage not defined, set most latest company
      if(!_currentCompany || _currentCompany == "null"){
        localStorage.setItem("activeCompany", data[data.length - 1].id)
        this.currentCompany = data[data.length - 1]
      }else{
        this.currentCompany = data.find(v=>v.id == _currentCompany)
      }

      //store companyList
      this.companies = data
    },
    getMainDomain() {
			const hostname = window.location.hostname;
			const parts = hostname.split('.');
			if (parts.length > 2) {
				parts.shift(); 
			}
			return parts.join('.');
		},
    async switchCompany(c) {
      //console.log("switchCompany", c);
      if(!c) return

			this.loading = true;
			window.scrollTo(0, 0);
			await Utils.sleep(300);
      this.currentCompany = c
			this.loading = false;
			Cookies.set(Constant.COOKIE_PREFERED_SELECTED_COMPANY_ID, c?.id, { domain: `.${this.getMainDomain()}` });
    },
    isClientOfCorpsec() {
      const list = this.currentCompany?.clientOf || [];
      console.log("list", list)
      return list.includes("corpsec");
    },
    isClientOfCorpsecLite() {
      const list = this.currentCompany?.clientOf || [];
      console.log("list", list)
      return list.includes("corpsec-lite") || !list.length;
    },

    //loynote: slowly phase off useless func above

    async previewCompanyDocument (key, sessionAccessToken) {
      return companyService.previewCompanyDocument(key, sessionAccessToken);
    },

    //loynote: all company info shd not have these until backend prevent returning these sensitve/obsolete data.
    _tidyCompanyData (d) {
      if (!d) { return }
      delete d._type;
      delete d.pk;
      delete d.sk;
      delete d.gs0pk;
      delete d.gs0sk;
      delete d.gs1pk;
      delete d.gs1sk;
      delete d.gs2pk;
      delete d.gs2sk;
      //delete d.id;
      delete d.organizationId;
      delete d.auditStreamId;
      delete d.keywordSearch;
      delete d.createdBy;
      delete d.updatedBy;
    
      delete d.articlesConstitution;
      delete d.feeInformation;
      delete d.internalCSSStatus;
      delete d.nameEffectiveFrom;
      delete d.companyContactDetails;
    
      delete d.businessOpAddress?.physicalStore;
      delete d.registeredAddress?.addressType;

      //delete d.clientOf;
      delete d.status;
      delete d.createdAt;
      delete d.updatedAt;
      delete d.userRole;
      delete d.riskAssessmentRating;
      //console.log("getCompanyById", d.internalNote)
      return d;


      return d;

    }

  }
})