<template>
  <nav class="navbar gap-3">
    <button class="nav-hamburger cursor-pointer px-0 bg-white border-none md:hidden" @click="onToggle">
      <i class="pi pi-bars text-xl text-800 vertical-align-middle"></i>
    </button>
    <!-- <button class="nav-briefcase cursor-pointer px-0 bg-white border-none" @click="onToggle">
      <i class="pi pi-briefcase text-xl text-800"></i>
    </button>     -->
    <Logo class="" />
    <NavBarMenu class="ml-auto" />
  </nav>
</template>

<script setup>
// import Button from 'primevue/button'
import Logo from '@/views/_layout/_NavLogo.vue'
import NavBarMenu from '@/views/_layout/_NavBar.Menu.vue'
import { useStoreSite } from '@/stores/store.site'

const storeSite = useStoreSite()

const onToggle = () => {
  storeSite.toggleSideBar();
  //console.log("wtfd");

}
</script>

<style scoped lang="scss">


.navbar {
  color: #404040;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  height: 64px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 24px;
  padding-right: 24px;
  width: 100%;
  z-index: 3;
  border-bottom: 0 solid transparent;
  
  // .nav-hamburger { display: none; }
  // .nav-briefcase { display: block; }

  @media screen and (min-width: $break-small) {
    //height: 30px;
    border-bottom: 1px solid #e1e2e6;
    .nav-hamburger { display: block; }
    //.nav-briefcase { display: none; }
  }
}

</style>
