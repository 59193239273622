<template>
  <!-- BLANK LAYOUT -->
  <!-- old -->

  <div class="site-wrapper">
    <nav class="navbar"></nav>
    <div class="content-wrapper">
      <header></header>
      <main><router-view /></main>
    </div>
    <footer></footer>
  </div>
</template>


<script setup>
import NavLogo from "@/views/_layout/_NavLogo.vue";
import { onMounted } from "vue";


onMounted(() => {
  // //console.log("mounted...");
});
</script>

<style lang="scss" scoped>


  .site-wrapper {
  //   display: flex;
  //   flex-direction: column;
  //   min-height: 100vh;
  height: 100%;
  background-color:  #F4F7FF;
  }

  .content-wrapper {
  //   flex: 1; 
    // height: 100%;
    min-height: 100vh;
  }


  // footer {
  //   flex-shrink: 0; 
  //   margin-top: auto; 
  // }

</style>