<script setup>
import { ref, computed } from "vue"
import Column from "primevue/column"
import Skeleton from "primevue/skeleton"
import DataTable from "primevue/datatable"
import { FilterMatchMode } from "primevue/api"

const props = defineProps({
	value: { type: Array, default: [] },
	isLoading: { type: Boolean, default: false },
	columns: { type: Array, default: [] }, // eg: [{field: 'someField', header:'someHeader', sortable:true, filter:true}]
})

/*** states ***/
const filters = ref(null)
// column options = field:String, header:String, sortable:Boolean, filter:Boolean, style:Object
const columnDefaultOptions = { sortable: true, filter: false }
const computedColumns = computed(() => {
	return props.columns.map((column) => ({ ...columnDefaultOptions, ...column}))
})

/*** util functions ***/
function initFilters() {
	let filterOptions = {
		global: { value: null, matchMode: FilterMatchMode.CONTAINS },
	}
	for (let i = 0; i < props.columns.length; i++) {
		filterOptions[`${props.columns[i].field}`] = { value: null, matchMode: FilterMatchMode.CONTAINS }
	}
	return filterOptions
}

/*** actions ***/
filters.value = initFilters()
</script>

<template>
		<!-- :pt="{
			root:{style:{overflow:'auto', maxWidth:'100vw'}},
		}" -->
<!-- <div style="max-width: 100vw; overflow:auto; pointer-events: auto;"> -->
	<DataTable
		v-model:filters="filters"
		size="small"
		paginator
		:rows="20"
		:rowsPerPageOptions="[20, 50, 100]"
		:value="props.value"
		:paginatorTemplate="{
			'640px': 'PrevPageLink CurrentPageReport NextPageLink',
			'960px': 'FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink',
			'1300px': 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink',
			// default: 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink ',
		}"
		>
		<template #empty>
			<p class="text-center"><i>No data found.</i></p>
		</template>
		<template #header>
			<div class="flex justify-content-end">
				<slot name="header"></slot>
				<!-- <span class="p-input-icon-left"> -->
					<!-- <i class="pi pi-search" /> -->
					<InputText v-model="filters['global'].value" placeholder="Keyword Search" />
				<!-- </span> -->
			</div>
		</template>
		<Column
		:class="column.class"
			:style="column.style"
			:filterField="column.field"
			:sortable="column.sortable"
			v-for="(column, i) in computedColumns"
			:key="i"
			:field="column.field"
			:header="column.header">
			<template #body="{ data, index }">
				<skeleton v-if="props.isLoading" class="h-2rem"></skeleton>
				<slot v-else :name="column.field" :data="data" :index="index">
					{{ data[column.field] }}
				</slot>
			</template>
			<template v-if="column.filter" #filter="{ filterModel, filterCallback }">
				<slot :name="`${column.field}Filter`" :filterModel="filterModel" :filterCallback="filterCallback">
					<InputText
						v-model="filterModel.value"
						type="text"
						@input="filterCallback()"
						class="p-column-filter"
						placeholder="Search" />
				</slot>
			</template>
		</Column>
	</DataTable>
<!-- </div> -->
</template>

<style scoped lang="scss">


:deep(.p-datatable-tbody > tr > td) {
	padding-top:1.2rem !important;
	padding-bottom:1.2rem !important;
}

.p-datatable  {
	:deep(.p-datatable-thead > tr > th) {
		// background-color: transparent;
		background-color:var(--surface-50)
	}

	:deep(.p-datatable-header) {
		background: transparent;
	}
	:deep(.p-datatable-wrapper) {
		// overflow: visible !important;
		
	}

	tr {
		padding-top:24px;
		padding-bottom:24px;
	}

	:deep([data-pc-section="filtermenubutton"]) {
	display: none;
}
}
</style>
