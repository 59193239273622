<script setup>
import {computed} from 'vue'
import Button from 'primevue/button'

const props = defineProps(
    {
        type:{type:String, default:''}
    }
)
const addOnClass = computed(() => ({
    'c-dotted' : props.type == 'dotted'
}))
</script>

<template>
    <Button :class="addOnClass"><slot></slot></Button>
</template>

<style scoped lang="scss">
.p-button {
    background-color: #ffdb00;
	border:1px solid #ffdb00;
	color: #000624;

	&:enabled:hover {
		background: #f2d000;
		color: #404040;
		border-color: #f2d000;
	}
 
    &.p-button-outlined {
        color: #404040;
    }
    &.p-button-outlined:enabled:hover {
        color: #404040;
        background-color: var(--surface-100);
    }
    &.p-button-text {
        color: var(--surface-500)
    }

    &.c-dotted {
        border:1px dashed var(--surface-400);
        &:hover {
            border:1px dashed var(--surface-400) !important; 
            background-color: var(--surface-400);
        }
    }
}
.p-button.p-button-text:enabled:active {
    background: transparent
}
</style>