import { defineStore  } from 'pinia'
import MainLayout from '@/views/_layout/Layout.Main.vue';
import Main800Layout from '@/views/_layout/Layout.Main.800.vue';
import BlankLayout from '@/views/_layout/Layout.Blank.vue';
import LandingLayout from '@/views/_layout/Layout.Landing.vue';
import OnboardLayout from '@/views/_layout/Layout.Onboard.vue';
import Constant from "@/helpers/Constant";
import Cookies from 'js-cookie'
import Utils from '@/helpers/Utils';
import ShareFunc from "@/helpers/ShareFunc";
import { siteService } from '@/services/service.site';
import _ from 'lodash';

import { useStoreCompany } from "@/stores/store.company"

import env from "@/helpers/Environment"


import iconCentral from '@/assets/icons/menu/central.png';
import iconBanking from '@/assets/icons/menu/banking.png';
import iconAccounting from '@/assets/icons/icon-accounting.svg';
import iconCollection from '@/assets/icons/menu/collection.png';
import iconPayroll from '@/assets/icons/menu/payroll-1.png';
import iconSalesChannel from '@/assets/icons/menu/ARAP.png';
import iconCorporate from '@/assets/icons/menu/corporate.png';
import iconBrochure from '@/assets/icons/menu/ebrochure.png';
import iconCrm from '@/assets/icons/menu/CRM.png';
import iconErp from '@/assets/icons/menu/ERP.png';
// import iconPaymentLink from '@/assets/icons/icon-payment-link.png';
import iconPos from '@/assets/icons/menu/POS.png';


const profileMenuItems = [
  
  {
    label: 'Sign Out',
    icon: `pi pi-sign-out`,
    func: () => { 			
			location.replace(env.ACCOUNT_SIGN_OUT_URL) 
    },
    feature: Constant.DISABLED,
  },
]
const mbsItems = [
	// {
	// 	title: "Corporate Compliance",
	// 	description: "Effortlessly manage all your company's corporate affairs here.",
	// 	func: () => {
  //     const store = useStoreCompany();
  //     window.open(ShareFunc.generateRedirect(env.CORPSEC_FE_COMPANY_ID, store.selectedCompany.companyId), "_blank")
	// 		// if (isNewUser.value == true) {
	// 		// 	window.open(env.OIDC_AUTH_CORPSEC, "_blank")
	// 		// } else {
	// 		// 	window.open(ShareFunc.generateRedirect(env.CORPSEC_FE_COMPANY_ID, selectedCompany.value.companyId), "_blank")
	// 		// 	//console.log ("onClickCorpModule",ShareFunc.generateRedirect(env.CORPSEC_FE_COMPANY_ID, selectedCompany.value.companyId))
	// 		// }
	// 	},
	// 	img: iconCorporate
	// },
  {
    	title: "Home",
    	description: "",
    	func: async () => {
        const link = await ShareFunc.generatePKCE(env.CENTRAL_AUTH_URL)
        //safari redirect fix attempt
        setTimeout(()=>{window.open(link, "_blank")},10)
    	},
    	img: iconCentral
    },
	{
		title: "Digital Banking",
		description: "Banking at your fingertips with our seamless digital banking services.",
		func: ()=>{ window.open(env.DEMO_DIGITAL_BANK, "_blank") },
		img: iconBanking,
    // disabled:true
	},
	{
		title: "Pay & Receive", 
		description: "Send and receive globally, scale your business fast.",
		img: iconCollection,
		disabled:true,
	},
]

const vasItems = [
  {
    title: "Payroll",
    id: "payroll",
    description: "Manage leave application, retrieve payslip and more.",
    img: iconPayroll,
    func: ()=>{ 
      const storeCompany = useStoreCompany();
      if(!storeCompany.companies.length){
        window.open(env.LANDING_DEMO_LINK_PAYROLL, "_blank")
      }else{
        window.open(env.DEMO_LINK_PAYROLL, "_blank")
      }
    }
  },  
  {
    title: "ERP",
    id: "erp",
    description: "Improve the productivity of your company with an all-in-one centralised system.",
    img: iconErp,
    func: ()=>{ 
      const storeCompany = useStoreCompany();
      if(!storeCompany.companies.length){
        window.open(env.LANDING_DEMO_LINK_ERP, "_blank")
      }else{
        window.open(env.DEMO_LINK_ERP, "_blank")
      }
    }

  },
  {
    title: "POS",
    id: "erp",
    description: "Point of Sales software for easy payment collections.",
    img: iconPos,
    func: ()=>{ 
      const storeCompany = useStoreCompany();
      if(!storeCompany.companies.length){
        window.open(env.LANDING_DEMO_LINK_POS, "_blank")
      }else{
        window.open(env.DEMO_LINK_POS, "_blank")
      }
    }
  },

  {
    title: "E-Brochure",
    id: "erp",
    description: "Clinch sales on the go. With mobile invoicing and payment features, boost your sales today with MEYZER360 E-brochure module.",
    img: iconBrochure,
    func: ()=>{ 
      const storeCompany = useStoreCompany();
      if(!storeCompany.companies.length){
        window.open(env.LANDING_DEMO_LINK_ERP, "_blank")
      }else{
        window.open(env.DEMO_LINK_EQUOTATION, "_blank")
      }
    }
  },
  {
    title: "SRM",
    id: "erp",
    description: "Deliver greater value through strategic sourcing. Assess suppliers' strengths, performance and capabilities to your overall business strategy.",
    img: iconCrm,
    func: ()=>{ 
      const storeCompany = useStoreCompany();
      if(!storeCompany.companies.length){
        window.open(env.LANDING_DEMO_LINK_ERP, "_blank")
      }else{
        window.open(env.DEMO_LINK_SRM, "_blank")
      }
    }
  },
  {
		title: "ARAP",
    id: "erp",
		description: "Streamline your accounts payable & receivable workflow.",
		img: iconSalesChannel,
		func: ()=>{ 
      const storeCompany = useStoreCompany();
      if(!storeCompany.companies.length){
        window.open(env.LANDING_DEMO_LINK_ARAP, "_blank")
      }else{
        window.open(env.DEMO_LINK_ARAP, "_blank")
      }
    }
	},
  

  

]

const obj = {
  init: false,
  apiVersion: { api:null, env:null, stageEnv:null },
  title: Constant.SITE_TITLE,
  metadata: [],
  layout: 'blank',
  layoutView: BlankLayout,
  wasLoggedIn: localStorage.getItem(Constant.STORAGE_WAS_LOGGED_IN) == "true",
  profileMenuItems: profileMenuItems,
  mbsItems: mbsItems,
  vasItems: vasItems,
  isMobile:false,
  sideBarFlag: false,
  incorpOverlayFlag:false,  
};

export const useStoreSite = defineStore('site', {

  state: () => obj,
  getters: {
    hadShownWelcome: (state) => { 
      return Cookies.get(Constant.COOKIE_HAD_SHOWN_WELCOME) == "true"
    },
  },
  actions: {
    async getApiVersion() {
      const res = await siteService.getApiVersion();
      // console.log("res", res)
      this.apiVersion = res.data;
    },    
    initialise() {
      this.init = true;
    },
    setTitle(title) {
      this.title = title;
    },
    setMetadata(metadata) {
      this.metadata = metadata;
    },
    setLayout(layout) {
      let v = MainLayout;
      if (layout == 'main') {
        v = MainLayout;
      } else if (layout == 'landing') {
        v = LandingLayout;
      } else if (layout == 'onboard') {
        v = OnboardLayout;
      } else if (layout == 'blank') {
        v = BlankLayout;
      } else if (layout == 'main-800px') {
      v = Main800Layout;
    } 
      this.layout = layout;
      this.layoutView = v;
    },
    promptLogin(prompt = true) {
      //ShareFunc.track("site-prompt-login", "site", "show", prompt);
      this.loginPrompt = prompt;
    },
    checkIsMobile() {
      let flag = false;
      //flag = Utils.isMobile();
      //$break-xx-small: 360px;
      //$break-x-small: 480px;
      //$break-small: 768px;
      const w = window.innerWidth
      if (w <= 768) {
        flag = true;
      }
      this.isMobile = flag;
      // console.log("checkIsMobile", flag)
      return flag;
    },
    toggleSideBar() {
      //console.log("toggleSideBar");
      this.sideBarFlag = !this.sideBarFlag;
    },
    showIncorpOverlay(flag = true) {
      this.incorpOverlayFlag = flag;
    },


    async getThumbnailUrl (key, t, size = "128") {
      //rename to .jpg
      const img = key.replace(/\.[^/.]+$/, ".jpg")
      const k = `thumbnail/${size}/${img}`

      //todo: need to do the session access token for security
      //binary return not working, return the url for now

      return siteService.getPreviewFile(k);

      // let u = env.API_URL;
      // const url = `${u}/site/preview/${k}?t=${t}`;
      // return url;
    },

    async getPreviewFileUrl (key) {
      let u = env.API_URL;
      const url = `${u}/site/preview/${key}`;
      return url;
    },


    async showToast(summary = "Test", detail = "Test ok", severity = "success", life = 3000) {
      const {app} = await import("@/main");
      app.config.globalProperties.$toast.add({ severity: severity, summary: summary, detail:detail, life:life });
    },

    async showConfirm (acceptFunc, rejectFunc, opts={}) {
      const optsDefault = {header:"Confirmation", message:"Are you sure you want to proceed?", icon:"pi pi-exclamation-triangle"}
      const o = _.extend(optsDefault, opts);
      //console.log("1:", opts, "2: ", o)
      const {app} = await import("@/main");
      app.config.globalProperties.$confirm.require({
          escape: 'true',
          header: o.header,
          message: o.message,
          icon: o.icon,
          acceptLabel:o.acceptLabel, 
          rejectLabel:o.rejectLabel, 
          accept: async () => { acceptFunc && acceptFunc(); },
          reject: () => { rejectFunc && rejectFunc(); },
      })
    },

    async showInvalidClientOf () {
      this.showConfirm(()=>{
        const url = env.CENTRAL_AUTH_URL + "?redirect=contact";//&query=Corporate%20Secretarial%20Services
        window.open(url, "_blank")
      }, ()=>{}, {
        header:"Transfer your company to MEYZER360",
        message:"Appoint MEYZER360 as your company secretary and discover our tools to supercharge your business.",
        acceptLabel:"Transfer Now", 
        rejectLabel:"Later"
      });
    }



  },

})

