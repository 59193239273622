import axios from 'axios';
import env from '@/helpers/Environment';
import { showToast } from '@/components/Notification';

import { useStoreAccount } from '@/stores/store.account';
import { useStoreSite } from '@/stores/store.site';
import Constant from '../helpers/Constant';
import router from '@/router';
import Cookies from 'js-cookie'


const STATUS_OK = 200;
const STATUS_BAD_REQUEST = 400;
const STATUS_UNAUTHORIZED = 401;
const STATUS_FORBIDDEN = 403;
const STATUS_UNPROCESSABLE_ENTITY = 422;
const STATUS_TOO_MANY_REQUEST = 429;

const webApiError = {
  VALIDATION_ERROR: "validation_error",
  //MISSING_CREDENTIALS: "missing_credentials",
  INVALID_CREDENTIALS: "invalid_credentials",
  INVALID_CODE: "invalid_code",
  INVALID_SESSION: "invalid_session",
  USER_EXISTS: "user_exists",
  USER_NOT_VERIFIED: "user_not_verified",
  USER_DISABLED: "user_disabled",
  TOKEN_EXPIRED: "token_expired",
  EXCEEDED_MAX_ATTEMPT: "exceeded_max_attempt",
  SERVICE_UNAVAILABLE: "service_unavailable",
  REFRESH_TOKEN_EXPIRED: "refresh_token_expired",
  REFRESH_TOKEN_INVALID: "refresh_token_invalid",
  JWT_EXPIRED: "jwt_expired",
  JWT_INVALID: "jwt_invalid",
  MISSING_REFRESH_TOKEN: "missing_refresh_token",
};

const cancelToken = axios.CancelToken;
let cancelTokenSource = cancelToken.source();

const cancelPrevAxiosRequest = () => {
  console.log('cancelPrevAxiosRequest');
  cancelTokenSource.cancel();
  cancelTokenSource = new cancelToken.source();
};

axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.patch['Content-Type'] = 'application/json';
// axios.defaults.withCredentials = true;

axios.interceptors.response.use(
  async (res) => {
    //console.log("response: ", res);
    const { url } = res.config;
    const path = url.substring(env.API_URL.length, url.length);
    // console.log("response: ", path, res.data);
    //loynote: stupid axios got a data
    return res.data;
  },
  async (err) => {
    
    //const storeSite = useStoreSite();
    const res = err.response;
    //console.log('intercept error', err, res);

    if (res && res.config) {
      if (res.data?.code === webApiError.JWT_EXPIRED) {
        console.log("err config >>>>>>>> ",err.config);
        const storeAccount = useStoreAccount();
        const r = await storeAccount.refreshToken();
        const user = storeAccount.user; 
        if (r?.status == 'success' && user) {
          const originalRequest = err.config;
          originalRequest.headers.Authorization = user.accessToken; // update expired jwt
          originalRequest.headers.Token = user.idToken; // update expired id token
          return axios(originalRequest);
        }
      }
      if (res.data?.code === webApiError.REFRESH_TOKEN_EXPIRED || res.data?.code === webApiError.REFRESH_TOKEN_INVALID || res.data?.code === webApiError.MISSING_REFRESH_TOKEN) {
        //TODO: show warning toast "error message"
        //TODO:: remove token
        Cookies.remove(Constant.COOKIE_REFRESH_TOKEN);
        localStorage.setItem(Constant.STORAGE_WAS_LOGGED_IN, "false");
        // localStorage.removeItem('user');
        router.push('/');
      }

      if (res.status == STATUS_FORBIDDEN) {
       
      } else if (res.status == STATUS_UNAUTHORIZED) { 

      } else if (res.status == STATUS_UNPROCESSABLE_ENTITY) {

      } else if (res.status == STATUS_TOO_MANY_REQUEST) {        
        console.log('Too many request. Please try again later.', 'warning');
        //showMessage('Too many request. Please try again later.', 'warning');
      }
      return Promise.reject(res.data);
    }

    // console.log("CORS OR NETWORK ERROR");
    // TODO: do global alert for unexpected error

    err.data = { 
      status: "error", message: 'Service Unavailable - CORS or Network Error', 
      code: webApiError.SERVICE_UNAVAILABLE, data: {msg:"Service Unavailable"} 
    };
    console.log('Service Unavailable. Please try again later.', 'warning');
    // localStorage.removeItem("user");
    //showMessage('Service Unavailable. Please try again later.', 'warning');
    return Promise.reject(err.data);
  },
);

const callApi = async (method, endpoint, data, cancelable = true, blob = false) => {
  const url = env.API_URL + "/" + endpoint;
  const jsonData = JSON.stringify(data);

  const storeAccount = useStoreAccount();
  const jwt = storeAccount.user;
  //console.log("jwt", jwt);
  const headers = {
    'Content-Type': 'application/json',
    //Authorization: jwt,
    // 'Content-Type': 'application/json',
    'Authorization': jwt?.accessToken,
    'Token': jwt?.idToken,
  };
  return callApiUrl(method, url, jsonData, headers, cancelable, true, blob);
};

const callApiCentral = async (method, endpoint, data, cancelable = true) => {
  const url = env.CENTRAL_API_URL + "/" + endpoint;
  const jsonData = JSON.stringify(data);

  const storeAccount = useStoreAccount();
  const jwt = storeAccount.user;
  //console.log("jwt", jwt);
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': env.CENTRAL_KEY,
  };
  return callApiUrl(method, url, jsonData, headers, cancelable, true);
};

//without jwt
const callApi2 = async (method, endpoint, data, cancelable = true) => {
  const url = env.API2_URL + "/" + endpoint;
  const jsonData = JSON.stringify(data);
  const headers = {
    'Content-Type': 'application/json',
  };
  return callApiUrl(method, url, jsonData, headers, cancelable, false);
};

const callApiUrl = async (method, url, data, headers = {}, cancelable = true, withCredentials = true, blob = false) => {
  // console.log("url", url);
  //Loynote: fucking COR issues in local
  /*
  if (env.STAGE_ENV != "production") {
    withCredentials = false;
  }
  */

  const config = {
    method,
    url,
    withCredentials: true,
  };
  if (headers) { config.headers = headers; }
  if (data) { config.data = data; }
  if (blob) config.responseType = 'blob'
  if (cancelable) {
    config.cancelToken = cancelTokenSource.token;
  }

  // console.log("callApi config", config);
  return axios(config);
};

export {
  callApi, callApi2, callApiUrl, callApiCentral, cancelPrevAxiosRequest, webApiError,
};
