<script setup>
import { ref, computed } from "vue"
import router from "@/router"
import { useStoreCompany } from "@/stores/store.company"
import { useStoreSite } from '@/stores/store.site'
import {useRoute} from 'vue-router'
import env from '@/helpers/Environment'

import Message from 'primevue/message';

import CompanyBlock from "@/components/CompanyBlock.vue"
import PanelMenu from "primevue/panelmenu"

const storeCompany = useStoreCompany()
const storeSite = useStoreSite()
const route = useRoute()

const itemIsHidden = computed(() => !storeCompany.companies.length )
/*** states ***/
const items = ref([
	{ label: "Dashboard", to: "/dashboard", hidden: ['pending-processing','start-incorporate'].indexOf(route.name) > -1 },
	{ label: "Company", to: "/company", hidden: itemIsHidden },
	{ label: "Request", to: "/request", hidden: itemIsHidden },
	{ label: "Document", to: "/document", hidden: itemIsHidden },
	{ label: "Timeline", to: "/timeline", hidden: itemIsHidden },
])

/*** util functions ***/
function isMatchedRoute(routeName) {
	return route.path.includes(routeName.toLowerCase())
}

/*** event handlers ***/
const onClickItem = (event, item) => {
	event.preventDefault()
	if (item.url) {
		window.open(item.url, "_blank")
	} else {
		if(item.to) router.push(item.to)
		//mobile
		storeSite.sideBarFlag = false
	}
}


</script>

<template>
	<div class="card flex flex-column justify-content-center" id="container">
		<CompanyBlock/>
		<PanelMenu :model="items" class="w-full text-sm font-normal mt-2" style="padding-left:30px;">
			<template #item="slotProps">
				<div class="relative" style="padding-bottom:1px">
					<a
						v-if="slotProps.item.items && !slotProps.item.hidden"
						class="flex gap-2 align-items-center p-2 cursor-pointer text-custom-grey-2 border-round-lg">
						<span :class="[{ 'font-custom': slotProps.item.items }]" >
							{{ slotProps.item.label }}
						</span>
						<i class="pi pi-angle-down ml-auto"></i>
					</a>
					<a
						v-else-if="!slotProps.item.hidden"
						class="flex gap-2 align-items-center p-2 cursor-pointer no-underline text-custom-grey-2 font-custom"
						:class="[slotProps.item.disabled ? 'menuitem-custom-disabled' : '']"
						:href="slotProps.item.to"
						@click="(e) => onClickItem(e, slotProps.item)">
						<span :class="[{ 'font-custom': slotProps.item.items, 'text-primary' : isMatchedRoute(slotProps.item.label) }]">{{ slotProps.item.label }}</span>
						<Badge v-if="slotProps.item.badge" class="ml-auto" :value="slotProps.item.badge" />
					</a>
				</div>
			</template>
		</PanelMenu>

				
	
	</div>
</template>

<style scoped lang="scss">
.font-custom {
	font-weight: 600;
	color: rgba(0, 0, 0, 0.7) !important;
}
.company-btn-custom {
	background-color: transparent;
	border: 1px solid transparent;
	overflow: visible;
	background-color: #f5f6f8;
	border-radius: 8px;

	&.p-button {
		background-color: transparent;
		&:hover {
			background-color: #f5f6f8;
			border: 1px solid transparent;
		}
	}
}

.is-disabled {
	pointer-events: none;
	opacity: 0.5;
}

/* company menu */
.p-menu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover {
	// color: #495057;
	background: #f5f6f8 !important;
}

/* child item */
:deep(.p-panelmenu .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover) {
	background-color: #f5f6f8;
	border-radius: 8px;
}

/* active menu */
:deep(.p-panelmenu .p-panelmenu-header:not(.p-disabled).p-highlight .p-panelmenu-header-content) {
	background: transparent;
	border-color: transparent;
	border-radius: 8px;

	&:hover {
		background: #f5f6f8;
		border-color: transparent;
	}
}

:deep(.p-panelmenu .p-panelmenu-panel) {
	border: 0;
	// padding: 0.2rem;
	padding:0;
	margin-bottom:0;
}

/* parent not active*/
:deep(.p-panelmenu .p-panelmenu-header:not(.p-highlight):not(.p-disabled):hover .p-panelmenu-header-content) {
	background: #f5f6f8;
	border-radius: 8px;
}

:deep(.p-menuitem-content a) {
	padding-left: 1.5rem !important;
}

:deep(.p-panelmenu .p-panelmenu-content) {
	background-color: transparent;
	border: 0;
	//border: 1px solid transparent;
}

:deep(.p-panelmenu .p-panelmenu-header .p-panelmenu-header-content) {
	background-color: transparent;
	border: 0;
	//border: 1px solid transparent;
}
</style>











<!-- 
{
	label: "Request",
	hidden:itemIsHidden,
	items: [
		{ label: "My Request", to: "/request" },
		{ label: "New Request", to: "/request/new" },
	],
}, -->