<template>
    <hr class="divider-custom"/>
</template>

<style scoped lang="scss">
.divider-custom {
    border: 0;
    border-bottom: 1px solid #efeffd;
    margin:0;;
}
</style>