<script setup>
import { computed, ref, nextTick } from "vue"
import { storeToRefs } from "pinia"
import { useStoreCompany } from "@/stores/store.company"
import { useStoreSite } from "@/stores/store.site"
import * as ShareFunc  from '@/helpers/ShareFunc.js'
import Utils from "@/helpers/Utils"

import SvgBriefcase from "@/components/svg/SvgBriefcase.vue"
import SvgPlus from "@/components/svg/SvgPlus.vue"
import router from "@/router"
import { useRoute } from 'vue-router'

defineOptions({
	inheritAttrs: false,
})

const props = defineProps({
	pShowArrow: { type: Boolean, default: true },
})

const route = useRoute()

/** stores ***/
const storeCompany = useStoreCompany()
const storeSite = useStoreSite()
const { currentCompany } = storeToRefs(storeCompany)

/*** states ***/
const cCompanyId = computed(() => currentCompany?.value?.id ?? "")
const cCompanyRegNo = computed(() => currentCompany?.value?.registeredNumber ?? "")
const cBaseRole = computed(() => {
	let r = currentCompany?.value?.baseRole
	r = Utils.formatTitleCase(r)
	return r
})
const cPublishStatus = computed(() => {
	let r = currentCompany?.value?.publishStatus
	if (r == "draft") {
		r = "under review"
	}
	if (r == "published") {
		r = "active"
	}
	r = Utils.formatTitleCase(r)
	return r
})

const menuAppendTo = computed( () => {
	if (!storeSite.isMobile) {
		return '#company-btn-custom'
	} else {
		return '#sidebar-wrapper'
	}
})
const menuPositonStyle = computed(() => {
	if (!storeSite.isMobile) {
		return {
			top:'49.6px',
			left:'0',
			 zIndex:'100'
		}
	} else {
		return {
			top:'80px',
			left:'16px',
			 zIndex: 999
		}
	}
})
const menu = ref()
const cCompanyList = computed(() => {
	return formatCompanyList(storeCompany.companies)
})
function formatCompanyList(companies) {
	
	return companies.map((company) => ({
		data: company,
		label: ShareFunc.getCompanyName(company),
		command: async () => {
			await storeCompany.switchCompany(company)
			if (storeCompany.currentCompany.publishStatus == 'draft' || !storeCompany.companies.length) {
				if (route.name.includes('request') || route.name.includes('documents') ) {
					router.push('/dashboard')
				}
			}
		},
	}))
}

/** event handlers ***/
const onClickCompany = async (c) => {
	storeSite.toggleSelectCompanyDialog(false)
	//router.push("/dashboard");
	await storeCompany.onSelectCompany(c)
}

const toggle = (event) => {
	menu.value?.toggle(event)
}

const onClickAddNewCompany = () => {
	router.push('/onboarding')
}
</script>

<template>
	<template v-if="storeCompany.loading">
		<div class="flex flex-column">
			<Skeleton class="mb-2"></Skeleton>
			<Skeleton class="mb-2 w-8rem"></Skeleton>
		</div>
	</template>

	<template v-else-if="!cCompanyList?.length">
		<CustomButtonV3 disabled
        :rounded="false"
					class="w-full border-none bg-transparent hover:surface-100"
					style="padding-left: 5px; opacity:0.4; "
					@click="onClickAddNewCompany">
					<div class="flex gap-2 align-items-center">
            <SvgPlus />
						<p class="font-normal text-custom-muted text-custom-sm">New Company</p>
					</div>
				</CustomButtonV3>
	</template>

	<template v-else>
		<Button class="company-btn-custom flex gap-2 align-items-center" @click="toggle" id="company-btn-custom">
			<div>
				<SvgBriefcase />
			</div>
			<div class="text-left w-9rem" >
				<p class="title-custom-sm text-custom-title font-semibold text-custom-overflow-ellipsis">{{ ShareFunc.getCompanyName(currentCompany) }}</p>
				<p class="text-custom-xs text-custom-muted" v-if="cCompanyRegNo">Reg. No: {{ cCompanyRegNo }}</p>
			</div>
			<i class="ml-auto pi pi-angle-right" style="color: #404040"></i>
		</Button>
		<Menu ref="menu" id="overlay_menu" :model="cCompanyList" class="menu-custom shadow-5 text-sm" :popup="true" style="max-height:300px; max-width:224px; top:49.6px;left:2px; overflow-y:scroll" :appendTo="menuAppendTo" :style="menuPositonStyle">
			<template #item="slotProps">
				<div class="p-2">
					<!-- <div class="flex align-items-center justify-content-center icon-box-custom"></div> -->

					<div class="flex align-items-center gap-2">
						<div>
							<SvgBriefcase />
						</div>
						<div  style="max-width:80%">
							<p style="overflow:hidden; white-space:nowrap;" class="text-left text-overflow-ellipsis text-custom-title font-semibold">{{ slotProps.item?.label }}</p>
							<p
								v-if="slotProps.item.data?.registeredNumber"
								class="text-overflow-ellipsis text-custom-xs text-custom-muted text-left">
								Reg. No: {{ slotProps.item.data?.registeredNumber ?? "" }}
							</p>
						</div>
					</div>
				</div>
			</template>
			<template #end>
				<CustomButtonV3
        :rounded="false"
					class="w-full border-none bg-transparent hover-primary"
					@click="onClickAddNewCompany">
					<div class="flex gap-1 align-items-center" >
            <SvgPlus />
						<p class="text-sm text-custom-muted">New Company</p>
					</div>
				</CustomButtonV3>
			</template>
		</Menu>

	</template>
</template>

<style scoped lang="scss">
.company-btn-custom {
	background-color: transparent;
	border: 1px solid transparent;
	overflow: visible;
	background-color: #f5f6f8;
	border-radius: 8px;
	padding-left: 10px;
	padding-right: 8px;
	padding-bottom:9px;

	&.p-button {
		background-color: transparent;
		&:hover {
			background-color: #f5f6f8;
			border: 1px solid transparent;
		}
	}
	
}

:deep(.p-menu-end) {
	cursor:default;
}

// .truncated-holder {
// 	flex: 1;
// 	min-width: 0; /* or some value */
// 	max-width: 210px;
// 	div {
// 		white-space: nowrap;
// 		overflow: hidden;
// 		text-overflow: ellipsis;
// 	}
// }
// @media screen and (max-width: $break-small) {
// 	.truncated-holder {
// 		max-width: unset;
// 	}
// }
</style>
