import { callApi, callApiCentral } from '@/services/WebApi';

const newCompany = (data) => {
  return callApi('POST', `request/new-company`, data)
};

//get request for new company created by userId
const newCompanyUser = (userId) => {
  return callApi('GET', `request/new-company/user/`+userId)
};

/**
 * NOTE: To check whether this endpoint still there or not
 */
const newCompanyDetail = (reqId) => {
  return callApi('GET', `request/new-company/`+reqId)
};

const newCompanyDetailReply = (reqId, data) => {
  return callApi('PUT', `request/new-company/`+reqId+`/reply`, data)
};

/**
 * Should be deprecated
 */
const payment = (reqId) => {
  return callApi('PUT', `request/new-company/`+reqId+`/payment`)
};

const updatePayment = (reqId, companyId) => {
  return callApi('PUT', `requests/${reqId}/payment`);
};

const cancelIncorpRequest = (id) => {
  return callApi('DELETE', `request/new-company/${id}`);
};

const verifyIdentity = (id, email, index) => {
  return callApi('PUT', `request/new-company/${id}/resend-kyc`, { email, index });
};

const storeOnboardData = (data) => {
  // return callApi('POST', `companies/drafts`, data);
  return callApi('POST', `company-forms`, data);
  
};

const companyVerifyToken = (data) => {
  return callApi('POST', `companies/verify-token`, data);
};

const getAllCompany = (data) => {
  return callApi('GET', `companies`, data);
};

const getCompany = (id) => {
  return callApi('GET', `companies/`+id, {});
};

const submitOnboardData = (id, data) => {
  return callApi('PUT', `companies/drafts/${id}`, data);
};

const resendKYC = (id, data) => {
  return callApi('PUT', `companies/drafts/${id}/resend-kyc`, data);
};

const resendKYCDirector = (id, data) => {
  return callApi('PUT', `companies/drafts/${id}/resend-kyc/director`, data);
};

const resendKYCShareholder = (id, data) => {
  return callApi('PUT', `companies/drafts/${id}/resend-kyc/shareholder`, data);
};

const getPaymentLink = (data) => {
  return callApi('POST', 'payment/link', data);
};

const getDashboardTimeline = (id, data) => {
  return callApi('GET', `events/company/${id}`, data);
};

// Requests
const createRequest = (companyId,data) => {
  return callApi('POST', `requests`, { companyId, ...data});
};

const getRequestByCompanyId = (companyId) => {
  return callApi('GET', `requests/company/${companyId}`);
};

const getRequestById = (companyId, requestID) => {
  return callApi('GET', `requests/${requestID}`);
};
const replyRequest = (companyId, requestID, data) => {
  return callApi('PUT', `requests/${requestID}/reply`, data);
};
const cancelRequest = (companyId, requestID, data) => {
  return callApi('DELETE', `requests/${requestID}`, data);
};
const acceptQuotation = (companyId, requestId, data) => {
  return callApi('PUT', `requests/${requestId}/accept`, data)
}
const rejectQuotation = (companyId, requestId, data) => {
  return callApi('PUT', `requests/${requestId}/reject`, data)
}

const getRequestTypesList = () => {
  return callApi('GET', `fees`)
}
const getRequestType = (requestTypeId) => {
  return callApi('GET', `fees/${requestTypeId}`)
}

//22 nov 2023 central api
const centralRegisterCompanyCorpsec = (data) => {
  return callApiCentral('POST', `ext/register-company-corpsec-public`, data)
}



const getDocuments = (companyId) => {
  return callApi('GET', `documents/company/${companyId}`);
}
const getRequests = (companyId) => {
  return callApi('GET', `requests/company/${companyId}`);
}
const getEvents = (companyId) => {
  return callApi('GET', `events/company/${companyId}`);
}

export const requestService = {
  newCompany,
  newCompanyUser,
  newCompanyDetail,
  newCompanyDetailReply,
  payment,
  cancelIncorpRequest,
  verifyIdentity,
  storeOnboardData,
  companyVerifyToken,
  getAllCompany,
  getCompany,
  submitOnboardData,
  resendKYC,
  getPaymentLink,
  getDashboardTimeline,
  updatePayment,

  createRequest,
  getRequestByCompanyId,
  getRequestById,
  replyRequest,
  cancelRequest,

  acceptQuotation,
  rejectQuotation,

  resendKYCShareholder,
  resendKYCDirector,

  getRequestTypesList,
  getRequestType,

  centralRegisterCompanyCorpsec,

  
  getDocuments,
  getRequests,
  getEvents
};