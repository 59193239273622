<template>
  <footer class="p-3">
    <div class="footer-section-left">
      <div class="logo-holder">
        <i class="ico logo logo-mz-4 hidden md:block"></i>
      </div>
    </div>
    <div class="footer-section-right">
      <div class="version-service-policy text-right">
        <div class="mr-2 text-center">v{{ version }} {{stageEnv}}</div>
        | &nbsp;
        <div class="mr-2 text-center"><a class="no-underline" href="https://www.meyzer360.com/terms-conditions/" target="_blank">Term of Service</a></div>
        | &nbsp;
        <div class="policy text-center"><a class="no-underline" href="https://www.meyzer360.com/privacy-policy/" target="_blank">Privacy Policy</a></div>
        <span class="line-next-to-policy">| &nbsp;</span>
      </div>
      <div class="text-center md:text-right">&copy; {{ new Date().getUTCFullYear() }} MEYZER360 All Right Reserved.</div>
    </div>
  </footer>
</template>

<script setup>
//pinia store
import { useStoreSite } from '@/stores/store.site'

//others
import env from "@/helpers/Environment";

const storeSite = useStoreSite();
const version = __APP_VERSION__;
const stageEnv = env.STAGE_ENV == "prod" ? "" : env.STAGE_ENV;
const apiVersion = storeSite.apiVersion;
</script>

<style scoped lang="scss">

footer {
  position: relative;
  display: flex;
  justify-content: space-between;
  font-family: $font-primary;
  font-size: 0.8rem;
  color: $font-footer-color-dark;
  padding: 8px;
  .footer-section-left {
    .logo-holder {
    position: absolute;
    top: 9px;
      .logo {
        width: 60.57px;
        height: 10.96px;
      }
    }
  }
  .footer-section-right {
    .version-service-policy {
      display: flex;
      .line-next-to-policy {
        display: none;
      }
    }
  }
}



@media screen and (min-width:$break-small) {
  footer {
    // color: $font-footer-color-dark;
    .footer-section-right {
      display: flex;
      .version-service-policy {
        .policy {
          margin-right: 0.5rem;
        }
        .line-next-to-policy {
        }
      }
    }
  }
}
</style>
