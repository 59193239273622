const mode = import.meta.env.MODE;

let env = "local";
if (mode != "development") {
  env = mode;
}
//oidc provider
//https://localhost:3002/v1/oidc/.well-known/openid-configuration
//https://api.central.meyzer.xyz/v1/oidc/.well-known/openid-configuration
//const env = "local";
//const env = "stage";
//const env = "prod";

if(env != 'prod') console.log("*** system running in env:", env, " ***");

// ## ENDPOINT ##
let corpsecFrontendUrl = '',
  corpsecBackendUrl = '',
  centralFrontendUrl = '',
  centralBackendUrl = '',
  oidcProvider = '',

  // ## GTM ##
  gtm = '',

  // ## CORPSEC ##
  corpsecAuthUrl = '',
  apiUrl = '',
  
  // ## OIDC ##
  oidcRedirectUrl = '',
  oidcCentralRedirectUrl = '',
  accountSignOutUrl = '',

  // ## CENTRAL ##
  centralApiUrl = '',
  centralApiKey = '',

  // ## 9 DOTS MENU / NAV ##
  centralAuthUrl = '',
  oidcAuthCentral = '',
  oidcAuthCorpsec = '',
  payrollUrl = '',
  digitalBankingUrl = '',
  accountingUrl = '';

  let landing_demo_link_erp = "https://meyzer360.com/erp/"
  let landing_demo_link_payroll = "https://meyzer360.com/hr-payroll-compliance/"
  let landing_demo_link_pos = "https://meyzer360.com/cloud-pos/"
  let landing_demo_link_crm = "https://meyzer360.com/crm/"
  let landing_demo_link_ebrochure = "https://meyzer360.com/erp/"
  let landing_demo_link_arap = "https://meyzer360.com/erp/"

  let demo_digital_bank =  "https://digitalbanking.meyzer360.com/dashboard";
  let demo_link_sales =  "https://sales.meyzer360.site/superapp/setup.php";
  // let demo_link_erp =  "https://sales.meyzer360.site/index.php?mainmenu=home&idmenu=16418";
  // let demo_link_payroll =  "https://hrm.meyzer360.com/sys/"; //"https://payroll.meyzer360.com/mroll/sys/";
  // let demo_link_pos =  "https://sales.meyzer360.site/takepos/index.php?idmenu=33836&mainmenu=takepos&leftmenu="; //"https://sales.meyzer360.site/leads/propal/list.php?mainmenu=commercial&leftmenu=leads&idmenu=32799&load=1";
  let demo_link_crm =  "https://aiosw.com/user2/meyzer_1sme/";
  let demo_link_ebrochure =  "https://sales.meyzer360.site/custom/brochure/view.php?mainmenu=commercial&leftmenu=brochures&idmenu=33060&load=1";

  let demo_link_payroll = "https://singroll.com/testing/meyzer_oauth";
  let demo_link_erp = "https://sales.meyzer360.site/meyzer_oauth?redirect=home"

  let demo_link_pos =  "https://sales.meyzer360.site/meyzer_oauth?redirect=pos";
  let demo_e_quotation = "https://sales.meyzer360.site/meyzer_oauth?redirect=e-quotation";
  let demo_link_srm = "https://sales.meyzer360.site/meyzer_oauth?redirect=suppliers"
  let demo_link_arap = "https://sales.meyzer360.site/meyzer_oauth?redirect=arap"

if (env == "local") {

  // ## GTM ##
  gtm = "GTM-000000";

  // ## ENDPOINT ##
  corpsecFrontendUrl = 'https://localhost:3000';
  corpsecBackendUrl = 'https://localhost:3004';
  centralFrontendUrl = 'https://localhost:3001';
  centralBackendUrl = 'https://localhost:3005';
  oidcProvider = 'https://localhost:3002';

  // ## CORPSEC ##
  corpsecAuthUrl = `${corpsecFrontendUrl}/authenticate`;
  oidcRedirectUrl =  `${corpsecFrontendUrl}/auth-callback`;
  apiUrl = `${corpsecBackendUrl}/v1`;
  
  // ## CENTRAL ##
  centralAuthUrl = `${centralFrontendUrl}/authenticate`;
  oidcCentralRedirectUrl =  `${centralFrontendUrl}/auth-callback`;
  centralApiUrl = `${centralBackendUrl}/v1`;
  centralApiKey = "SWfvrLlLIDsp4WRAi3OCtiTg49qll1jV473U6ZVwyTOiTCeho0svSdbTH4YGSnBw";  
  
  // ## 9 DOTS MENU / NAV ##
  oidcAuthCentral = `${oidcProvider}/auth?client_id=central&response_type=code&redirect_uri=${oidcCentralRedirectUrl}&code_challenge={code}&code_challenge_method={chmt}&scope=openid%20profile%20permissions`;
  oidcAuthCorpsec = `${oidcProvider}/auth?client_id=corpSec&response_type=code&redirect_uri=${oidcRedirectUrl}&code_challenge={code}&code_challenge_method={chmt}&scope=openid%20profile%20permissions`;
  payrollUrl = '<pending>';
  digitalBankingUrl = '<pending>';
  accountingUrl = '<pending>';
  accountSignOutUrl =  `${oidcProvider}/session/end?client_id=corpSec`;


} else if (env == "sit") {

  // ## GTM ##
  gtm = "GTM-000000";

  // ## ENDPOINT ##
  corpsecFrontendUrl = 'https://corpsec.meyzer360.rocks';
  corpsecBackendUrl = 'https://api.corpsec.meyzer360.rocks';
  centralFrontendUrl = 'https://central.meyzer360.rocks';
  centralBackendUrl = 'https://api.central.meyzer360.rocks';
  oidcProvider = 'https://account.meyzer360.rocks';


  // ## CORPSEC ##
  corpsecAuthUrl = `${corpsecFrontendUrl}/authenticate`;
  oidcRedirectUrl =  `${corpsecFrontendUrl}/auth-callback`;
  apiUrl = `${corpsecBackendUrl}/v1`;

  // ## CENTRAL ##
  centralAuthUrl = `${centralFrontendUrl}/authenticate`;
  oidcCentralRedirectUrl =  `${centralFrontendUrl}/auth-callback`;
  centralApiUrl = `${centralBackendUrl}/v1`;
  centralApiKey = "SWfvrLlLIDsp4WRAi3OCtiTg49qll1jV473U6ZVwyTOiTCeho0svSdbTH4YGSnBw";  
  
  // ## 9 DOTS MENU / NAV ##
  oidcAuthCentral = `${oidcProvider}/auth?client_id=central&response_type=code&redirect_uri=${oidcCentralRedirectUrl}&code_challenge={code}&code_challenge_method={chmt}&scope=openid%20profile%20permissions`;
  oidcAuthCorpsec = `${oidcProvider}/auth?client_id=corpSec&response_type=code&redirect_uri=${oidcRedirectUrl}&code_challenge={code}&code_challenge_method={chmt}&scope=openid%20profile%20permissions`;
  payrollUrl = '<pending>';
  digitalBankingUrl = '<pending>';
  accountingUrl = '<pending>';
  accountSignOutUrl =  `${oidcProvider}/session/end?client_id=corpSec`;

} else if (env == "stage") {

  // ## GTM ##
  gtm = "GTM-000000";

  // ## ENDPOINT ##
  corpsecFrontendUrl = 'https://corpsec.meyzer.xyz';
  corpsecBackendUrl = 'https://api.corpsec.meyzer.xyz';
  centralFrontendUrl = 'https://central.meyzer.xyz';
  centralBackendUrl = 'https://api.central.meyzer.xyz';
  oidcProvider = 'https://account.meyzer.xyz';


  // ## CORPSEC ##
  corpsecAuthUrl = `${corpsecFrontendUrl}/authenticate`;
  oidcRedirectUrl =  `${corpsecFrontendUrl}/auth-callback`;
  apiUrl = `${corpsecBackendUrl}/v1`;
  
  // ## CENTRAL ##
  centralAuthUrl = `${centralFrontendUrl}/authenticate`;
  oidcCentralRedirectUrl =  `${centralFrontendUrl}/auth-callback`;
  centralApiUrl = `${centralBackendUrl}/v1`;
  centralApiKey = "SWfvrLlLIDsp4WRAi3OCtiTg49qll1jV473U6ZVwyTOiTCeho0svSdbTH4YGSnBw";  
  
  // ## 9 DOTS MENU / NAV ##
  oidcAuthCentral = `${oidcProvider}/auth?client_id=central&response_type=code&redirect_uri=${oidcCentralRedirectUrl}&code_challenge={code}&code_challenge_method={chmt}&scope=openid%20profile%20permissions`;
  oidcAuthCorpsec = `${oidcProvider}/auth?client_id=corpSec&response_type=code&redirect_uri=${oidcRedirectUrl}&code_challenge={code}&code_challenge_method={chmt}&scope=openid%20profile%20permissions`;
  payrollUrl = '<pending>';
  digitalBankingUrl = '<pending>';
  accountingUrl = '<pending>';
  accountSignOutUrl =  `${oidcProvider}/session/end?client_id=corpSec`;

} else if (env == "prod") {

  // ## GTM ##
  gtm = "GTM-5WT25CCC";

  // ## ENDPOINT ##
  corpsecFrontendUrl = 'https://corpsec.meyzer360.com';
  corpsecBackendUrl = 'https://api.corpsec.meyzer360.com';
  centralFrontendUrl = 'https://central.meyzer360.com';
  centralBackendUrl = 'https://api.central.meyzer360.com';
  oidcProvider = 'https://account.meyzer360.com';

  // ## CORPSEC ##
  corpsecAuthUrl = `${corpsecFrontendUrl}/authenticate`;
  oidcRedirectUrl =  `${corpsecFrontendUrl}/auth-callback`;
  apiUrl = `${corpsecBackendUrl}/v1`;

  
  // ## CENTRAL ##
  centralAuthUrl = `${centralFrontendUrl}/authenticate`;
  oidcCentralRedirectUrl =  `${centralFrontendUrl}/auth-callback`;
  centralApiUrl = `${centralBackendUrl}/v1`;
  centralApiKey = "Dxj93sg763vqd4YUtqzbgZqhVP6PU9gzBf2sDPpnmcZYzSVvTMnZ4cHaJ4X5rZXv";  
  
  // ## 9 DOTS MENU / NAV ##
  oidcAuthCentral = `${oidcProvider}/auth?client_id=central&response_type=code&redirect_uri=${oidcCentralRedirectUrl}&code_challenge={code}&code_challenge_method={chmt}&scope=openid%20profile%20permissions`;
  oidcAuthCorpsec = `${oidcProvider}/auth?client_id=corpSec&response_type=code&redirect_uri=${oidcRedirectUrl}&code_challenge={code}&code_challenge_method={chmt}&scope=openid%20profile%20permissions`;
  payrollUrl = '<pending>';
  digitalBankingUrl = '<pending>';
  accountingUrl = '<pending>';
  accountSignOutUrl =  `${oidcProvider}/session/end?client_id=corpSec`;

  // ## DEMO LINK ##
  demo_digital_bank =  "https://digitalbanking.meyzer360.com/dashboard";
  demo_link_sales =  "https://go.meyzer360.com/superapp/setup.php";
  // demo_link_erp =  "https://go.meyzer360.com/index.php?mainmenu=home&idmenu=16418";
  // demo_link_payroll =  "https://hrm.meyzer360.com/sys/";
  // demo_link_/pos =  "https://go.meyzer360.com/takepos/index.php?idmenu=33836&mainmenu=takepos&leftmenu=";
  //demo_link_crm =  "https://go.meyzer360.com/leads/propal/list.php?mainmenu=commercial&leftmenu=leads&idmenu=32799&load=1";
  demo_link_crm =  "https://crm.meyzer360.com/";
  demo_link_ebrochure =  "https://go.meyzer360.com/custom/brochure/view.php?mainmenu=commercial&leftmenu=brochures&idmenu=33060&load=1";

  demo_link_payroll = "https://hrm.meyzer360.com/meyzer_oauth";
  demo_link_erp = "https://go.meyzer360.com/meyzer_oauth?redirect=home"
  demo_link_pos =  "https://go.meyzer360.com/meyzer_oauth?redirect=pos";
  demo_e_quotation = "https://go.meyzer360.com/meyzer_oauth?redirect=e-quotation";
  demo_link_srm = "https://go.meyzer360.com/meyzer_oauth?redirect=suppliers"
  demo_link_arap = "https://go.meyzer360.com/meyzer_oauth?redirect=arap"
}

export default {
  // BASE_URL: baseUrl,
  
  // ## VARS ##
  STAGE_ENV: env,
  GTM: gtm,

  // ## OIDC ##
  OIDC_URL: oidcProvider,

  // ## CORPSEC ##
  CORPSEC_AUTH_URL: corpsecAuthUrl,
  OIDC_REDIRECT_URL: oidcRedirectUrl,
  API_URL: apiUrl,

  // ## CENTRAL ##
  CENTRAL_AUTH_URL: centralAuthUrl,
  CENTRAL_API_URL: centralApiUrl,
  CENTRAL_KEY:centralApiKey,

  // ## 9 DOTS MENU / NAV ##
  OIDC_AUTH_CENTRAL: oidcAuthCentral,
  OIDC_AUTH_CORPSEC: oidcAuthCorpsec,
  PAYROLL_URL: payrollUrl,
  DIGITAL_BANKING_URL: digitalBankingUrl,
  ACCOUNTING_URL: accountingUrl,
  ACCOUNT_SIGN_OUT_URL: accountSignOutUrl,

  // ## DEMO LINK ##
  DEMO_DIGITAL_BANK: demo_digital_bank,
  DEMO_LINK_SALES: demo_link_sales,
  DEMO_LINK_ERP: demo_link_erp,
  DEMO_LINK_PAYROLL: demo_link_payroll,
  DEMO_LINK_POS: demo_link_pos,
  DEMO_LINK_CRM: demo_link_crm,
  DEMO_LINK_EBROCHURE: demo_link_ebrochure,
  DEMO_LINK_EQUOTATION: demo_e_quotation,
  DEMO_LINK_SRM: demo_link_srm,

  LANDING_DEMO_LINK_ERP: landing_demo_link_erp,
  LANDING_DEMO_LINK_PAYROLL: landing_demo_link_payroll,
  LANDING_DEMO_LINK_POS: landing_demo_link_pos,
  LANDING_DEMO_LINK_CRM: landing_demo_link_crm,
  LANDING_DEMO_LINK_EBROCHURE: landing_demo_link_ebrochure,
  LANDING_DEMO_LINK_ARAP:landing_demo_link_arap,

  
};
